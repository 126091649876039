
import { Vue, Options, prop } from "vue-class-component";
import { AccountController } from "@/app/ui/controllers/AccountController";
import {
  formatDateNumber,
  formatPrice,
  parsingErrorResponse,
  whatsapp,
} from "@/app/infrastructures/misc/Utils";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import {
  CourierPartnerCodDashboard,
  ListCourierPartnerCodDashboard,
  STTInDelivery,
  SummaryCourierPartnerCodDashboard,
} from "@/domain/entities/Dashboard";
import PopupSetorHarian from "@/app/ui/views/cod-delivery-v2/components/popup-setor-harian-kurir-v2.vue";
import PopupHarusDisetor from "@/app/ui/views/cod-delivery-v2/components/popup-harus-disetor.vue";
import moment from "moment";
import { FlagsPermissionCodReconcile } from "@/feature-flags/cod-reconcile";
import { QueryParamsEntities2 } from "@/domain/entities/MainApp";
import {
  CodDashboardRequest,
  DownloadSummaryCodRequest,
} from "@/data/payload/api/DashboardRequests";
import debounce from "lodash/debounce";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { CourierDetailPendingReconcile } from "@/domain/entities/CodReconcile";
import SummaryCodCourier from "./summary-cod-courier.vue";

class Props {
  isPicShuttle = prop<boolean>({
    default: false,
    type: Boolean,
  });
}
@Options({
  components: {
    PopupSetorHarian,
    PopupHarusDisetor,
    SummaryCodCourier,
  },
})
export default class ListCourierPartnerSection extends Vue.with(Props) {
  mounted() {
    DashboardController.setListCourierPartnerCodDashboard(
      new ListCourierPartnerCodDashboard()
    );
    this.searchValue = "";
    this.filterDate = new Date();
    this.fetchCourierPartner();
  }

  get listType() {
    if (this.isPicShuttle) {
      return "pic-shuttle";
    }
    return "courier";
  }

  // account type
  get dataProfile() {
    return AccountController.accountData;
  }
  get accountType() {
    return this.dataProfile.account_type;
  }
  get accountTypeDetail() {
    return this.dataProfile.account_type_detail.type;
  }

  get isPos() {
    return AccountController.accountData.isPosAccount;
  }

  get isConsole() {
    return AccountController.accountData.isConsoleAccount;
  }

  get isSubConsole() {
    return AccountController.accountData.isSubConsoleAccount;
  }

  get isFilter() {
    return (
      !this.dataCourierPartner.length &&
      (!!this.filterDate || !!this.searchValue)
    );
  }

  // search
  get searchValue() {
    return DashboardController.searchListCourierPartnerCodDashboard;
  }
  set searchValue(newValue: string) {
    DashboardController.setSearchListCourierPartnerCodDashboard(newValue);
  }
  onSearch = debounce(async (value: string) => {
    this.searchValue = value;
    DashboardController.setListCourierPartnerCodDashboard(
      new ListCourierPartnerCodDashboard()
    );
    this.clickHeader("courierName");
  }, 250);
  onClear() {
    this.searchValue = "";
    DashboardController.setListCourierPartnerCodDashboard(
      new ListCourierPartnerCodDashboard()
    );
    this.fetchCourierPartner();
  }

  // date
  get filterDate() {
    return DashboardController.filterDateCodDashboard;
  }
  set filterDate(newValue: Date) {
    DashboardController.setFilterDateCodDashboard(newValue);
  }
  onSelectDatePicker() {
    DashboardController.setListCourierPartnerCodDashboard(
      new ListCourierPartnerCodDashboard()
    );
    this.clickHeader("courierName");
  }

  amountDueDate = "";
  get filterDateString() {
    return moment(this.amountDueDate || this.filterDate).format("YYYY-MM-DD");
  }

  // table
  get firstRowHeaderColumns() {
    const { sortBy, orderBy } = this.courierPartnerListRequest;
    return [
      {
        name: "No.",
        styleHead: "w-12 text-left whitespace-nowrap",
        bgHeaderCell: true,
        bgRowCell: (item: CourierPartnerCodDashboard) =>
          !item.isHavePendingReconcile,
      },
      {
        name: "Nama Kurir",
        styleHead: "w-76 text-left font-bold whitespace-nowrap",
        icon:
          sortBy === "courierName"
            ? orderBy === "ASC"
              ? "chevron-up"
              : "chevron-down"
            : "chevron-up-down",
        onClickHeader: () => this.clickHeader("courierName"),
      },
      {
        name: `<div class="pb-2">Pengiriman Berhasil</div>`,
        colSpan: 2,
        styleHead: "w-350px text-center whitespace-nowrap font-bold",
        bgHeaderCell: true,
        bgRowCell: (item: CourierPartnerCodDashboard) =>
          !item.isHavePendingReconcile,
      },
      {
        name: `<div class="pb-2">DEX</div>`,
        colSpan: 2,
        styleHead: "w-350px text-center whitespace-nowrap font-bold",
      },
      {
        name: `<div class="pb-2">CODREJ</div>`,
        colSpan: 2,
        styleHead: "w-350px text-center whitespace-nowrap font-bold",
        bgHeaderCell: true,
        bgRowCell: (item: CourierPartnerCodDashboard) =>
          !item.isHavePendingReconcile,
      },
      {
        name: `<div class="pb-2">Dalam Pengiriman</div>`,
        colSpan: 2,
        styleHead: "w-500px text-center whitespace-nowrap font-bold",
      },
      {
        name: "Atur",
        key: "actionable_column",
        styleHead: "w-24 text-left font-bold",
        showButton: () => true,
      },
    ];
  }

  get toolTipHeaderBelumSetor() {
    return (
      "<div class='text-black-lp-300 text-12px text-left'>" +
      "Total nilai COD dari STT COD yang telah berhasil dikirim (POD) tetapi belum disetor oleh kurir kepada admin POS." +
      "</div>"
    );
  }
  get toolTipHeaderSudahSetor() {
    return (
      "<div class='text-black-lp-300 text-12px text-left'>" +
      "Total nilai COD dari STT COD yang telah berhasil dikirim (POD) dan telah disetor oleh kurir kepada admin POS." +
      "</div>"
    );
  }
  get columnsData() {
    const { sortBy, orderBy } = this.courierPartnerListRequest;
    return [
      // cod amount deposit
      {
        name: "Harus disetor",
        styleHead: "w-40 text-center whitespace-nowrap justify-center",
        styleCustom: "w-full justify-center",
        render: (item: CourierPartnerCodDashboard) =>
          `<span class='font-normal'>${formatPrice(
            item.codAmountDepositPending
          )}</span>`,
        icon:
          sortBy === "codAmountDepositPending"
            ? orderBy === "ASC"
              ? "chevron-up"
              : "chevron-down"
            : "chevron-up-down",
        onClickHeader: () => this.clickHeader("codAmountDepositPending"),
        bgHeaderCell: true,
        bgRowCell: (item: CourierPartnerCodDashboard) =>
          !item.isHavePendingReconcile,
      },
      {
        name: "Sudah disetor",
        styleHead: "w-40 text-center whitespace-nowrap justify-center",
        styleCustom: "w-full justify-center",
        render: (item: CourierPartnerCodDashboard) =>
          `<span class='font-normal'>${formatPrice(
            item.codAmountDepositCollected
          )}</span>`,
        icon:
          sortBy === "codAmountDepositCollected"
            ? orderBy === "ASC"
              ? "chevron-up"
              : "chevron-down"
            : "chevron-up-down",
        onClickHeader: () => this.clickHeader("codAmountDepositCollected"),
        bgHeaderCell: true,
        bgRowCell: (item: CourierPartnerCodDashboard) =>
          !item.isHavePendingReconcile,
      },
      // dex
      {
        name: "Harus disetor",
        styleHead: "w-40 text-center whitespace-nowrap justify-center",
        styleCustom: "w-full justify-center",
        render: (item: CourierPartnerCodDashboard) =>
          `<div class='text-black-lp-300 text-center font-semibold'>${item.totalSttDexPending} STT <span class='font-normal'>(${item.totalSttDexPendingPieces} koli)</span></div>`,
        icon:
          sortBy === "totalSttDexPending"
            ? orderBy === "ASC"
              ? "chevron-up"
              : "chevron-down"
            : "chevron-up-down",
        onClickHeader: () => this.clickHeader("totalSttDexPending"),
      },
      {
        name: "Sudah disetor",
        styleHead: "w-40 text-center whitespace-nowrap justify-center",
        styleCustom: "w-full justify-center",
        render: (item: CourierPartnerCodDashboard) =>
          `<div class='text-black-lp-300 text-center font-semibold'>${item.totalSttDexCollected} STT <span class='font-normal'>(${item.totalSttDexCollectedPieces} koli)</span></div>`,
        icon:
          sortBy === "totalSttDexCollected"
            ? orderBy === "ASC"
              ? "chevron-up"
              : "chevron-down"
            : "chevron-up-down",
        onClickHeader: () => this.clickHeader("totalSttDexCollected"),
      },
      // codrej
      {
        name: "Harus disetor",
        styleHead: "w-40 text-center whitespace-nowrap justify-center",
        styleCustom: "w-full justify-center",
        render: (item: CourierPartnerCodDashboard) =>
          `<div class='text-black-lp-300 text-center font-semibold'>${item.totalSttCodrejPending} STT <span class='font-normal'>(${item.totalSttCodrejPendingPieces} koli)</span></div>`,
        icon:
          sortBy === "totalSttCodrejPending"
            ? orderBy === "ASC"
              ? "chevron-up"
              : "chevron-down"
            : "chevron-up-down",
        onClickHeader: () => this.clickHeader("totalSttCodrejPending"),
        bgHeaderCell: true,
        bgRowCell: (item: CourierPartnerCodDashboard) =>
          !item.isHavePendingReconcile,
      },
      {
        name: "Sudah disetor",
        styleHead: "w-40 text-center whitespace-nowrap justify-center",
        styleCustom: "w-full justify-center",
        render: (item: CourierPartnerCodDashboard) =>
          `<div class='text-black-lp-300 text-center font-semibold'>${item.totalSttCodrejCollected} STT <span class='font-normal'>(${item.totalSttCodrejCollectedPieces} koli)</span></div>`,
        icon:
          sortBy === "totalSttCodrejCollected"
            ? orderBy === "ASC"
              ? "chevron-up"
              : "chevron-down"
            : "chevron-up-down",
        onClickHeader: () => this.clickHeader("totalSttCodrejCollected"),
        bgHeaderCell: true,
        bgRowCell: (item: CourierPartnerCodDashboard) =>
          !item.isHavePendingReconcile,
      },
      // del
      {
        name: "DEL",
        styleHead: "w-40 text-center whitespace-nowrap justify-center",
        styleCustom: "w-full justify-center",
        render: (item: CourierPartnerCodDashboard) =>
          `<div class='text-black-lp-300 text-center font-semibold'>${item.totalSttDel} STT <span class='font-normal'>(${item.totalSttDelPieces} koli)</span></div>` +
          `<div class='text-base text-center text-gray-lp-800'>${
            this.sttInDeliveryProcessDel.count
              ? Math.round(
                  (item.totalSttDel / this.sttInDeliveryProcessDel.count) * 100
                )
              : 0
          }%</div>`,
        icon:
          sortBy === "totalSttDel"
            ? orderBy === "ASC"
              ? "chevron-up"
              : "chevron-down"
            : "chevron-up-down",
        onClickHeader: () => this.clickHeader("totalSttDel"),
      },
      {
        name: "POD/CODREJ/DEX",
        styleHead: "w-40 text-center whitespace-nowrap px-2 justify-center",
        styleCustom: "w-full justify-center",
        render: (item: CourierPartnerCodDashboard) =>
          `<div class='text-black-lp-300 text-center font-semibold'>${item.totalSttPodCodrejDex} STT <span class='font-normal'>(${item.totalSttPodCodrejDexPieces} koli)</span></div>` +
          `<div class='text-base text-center text-gray-lp-800'>${
            this.sttInDeliveryProcessPodCodrejDex.count
              ? Math.round(
                  (item.totalSttPodCodrejDex /
                    this.sttInDeliveryProcessPodCodrejDex.count) *
                    100
                )
              : 0
          }%</div>`,
        icon:
          sortBy === "totalSttPodCodrejDex"
            ? orderBy === "ASC"
              ? "chevron-up"
              : "chevron-down"
            : "chevron-up-down",
        onClickHeader: () => this.clickHeader("totalSttPodCodrejDex"),
      },
      {
        name: "",
        key: "actionable_column",
        styleHead: "w-24 text-left",
        showButton: () => true,
      },
    ];
  }
  get columns() {
    return [
      {
        name: "No.",
        hide: true,
        styleHead: "w-12 text-left whitespace-nowrap justify-center",
        styleCustom: "w-full justify-center",
        render: (_: any, index: number) => {
          return `<div class=''>${index +
            1 +
            this.paginationCourierPartner.limit *
              (this.paginationCourierPartner.page - 1)}</div>`;
        },
        bgHeaderCell: true,
        bgRowCell: (item: CourierPartnerCodDashboard) =>
          !item.isHavePendingReconcile,
      },
      {
        name: "Nama Kurir",
        hide: true,
        key: "actionable_column",
        styleHead: "w-76 text-left whitespace-nowrap",
        render: (item: CourierPartnerCodDashboard) => {
          return (
            `<div class='text-black-lp-300 mr-4'>${`${item.courierId} - ${item.courierName}`}</div>` +
            `<div class='text-black-lp-300 mr-4'>${item.courierPhone}</div>`
          );
        },
        customValue: (item: CourierPartnerCodDashboard) =>
          item.isHavePendingReconcile
            ? `<div
                class="underline cursor-pointer text-red-lp-100"
              >
                Sisa harus disetor
              </div>`
            : "",
        clickCustomValue: this.openAmountDue,
        showButton: (item: CourierPartnerCodDashboard) => item.courierPhone,
        styleCustom: "items-end",
        icon: "whatsapp",
        iconStyle: "pt-4",
        disableButtonValue: () => false,
        onClickValue: this.onCLickWhatsapp,
      },
      ...this.columnsData,
    ];
  }
  buttonActions() {
    const temp = [
      {
        icon: "setor-harian-kurir",
        label: "Setor harian",
        clickFunction: this.openDailyDeposit,
      },
      {
        icon: "pencil-alt-disable",
        label: "Detail kurir",
        clickFunction: (item: any) => {
          const path =
            "/cod-delivery/cod-reconcile/cod-courier/detail-courier?";
          this.$router.push(
            `${path}${
              new QueryParamsEntities2({
                type: "courier",
                "driver-phone": item.courierPhone,
                date: formatDateNumber(this.filterDate),
              }).queryString
            }`
          );
        },
      },
      {
        icon: "download-gray",
        label: "Download detail kurir",
        clickFunction: (item: any) => {
          DashboardController.getCourierDetailDownload({
            driverPhone: item.courierPhone,
            isPic: false,
          });
        },
      },
    ];
    const isAbleToDeposit = FlagsPermissionCodReconcile.permission_cod_reconcile_deposit.isEnabled();

    if (!isAbleToDeposit && !this.isConsole && !this.isSubConsole) {
      temp.shift();
    }

    return temp;
  }

  clickHeader(key: string) {
    const { sortBy, orderBy } = this.courierPartnerListRequest;

    if (sortBy === key) {
      this.courierPartnerListRequest.orderBy =
        orderBy === "ASC" ? "DESC" : "ASC";
      this.courierPartnerListRequest.sortBy = orderBy === "ASC" ? key : "";
    } else {
      this.courierPartnerListRequest.orderBy = "ASC";
      this.courierPartnerListRequest.sortBy = key;
    }

    DashboardController.setListCourierPartnerCodDashboard(
      new ListCourierPartnerCodDashboard()
    );
    this.fetchCourierPartner();
  }

  // data table
  get courierPartnerListRequest(): CodDashboardRequest {
    return DashboardController.courierPartnerListRequest;
  }
  get dataCourierPartner() {
    return DashboardController.listCourierPartnerCodDashboard.data;
  }
  get paginationCourierPartner() {
    return DashboardController.listCourierPartnerCodDashboard.pagination;
  }
  get isLoadingCourierPartner() {
    return (
      DashboardController.loadingListCourierPartnerCodDashboard ||
      this.isLoadingSummary
    );
  }
  get isErrorCourierPartner() {
    return DashboardController.errorListCourierPartnerCodDashboard;
  }
  fetchCourierPartner() {
    this.fetchSummary();
    DashboardController.getListCourierPartnerCodDashboardV3();
  }

  summary = new SummaryCourierPartnerCodDashboard();
  get sttInDeliveryProcessDel(): STTInDelivery {
    return new STTInDelivery(
      this.summary.sttInDelivery.find(
        (key: STTInDelivery) => key.status.toLowerCase() === "del"
      )
    );
  }
  get sttInDeliveryProcessPodCodrejDex() {
    return this.summary.sttInDelivery
      .filter(
        (key: STTInDelivery) =>
          key.status.toLowerCase() === "pod" ||
          key.status.toLowerCase() === "codrej" ||
          key.status.toLowerCase() === "dex"
      )
      .reduce(
        (a: any, b: any) =>
          new STTInDelivery({
            count: a.count + b.count,
            pieces: a.pieces + b.pieces,
          }),
        new STTInDelivery()
      );
  }

  get isLoadingSummary() {
    return MainAppController.loadingModal;
  }
  async fetchSummary() {
    MainAppController.showLoading();
    this.summary = new SummaryCourierPartnerCodDashboard();
    try {
      MainAppController.closeErrorMessage();
      const resp = await DashboardController.getSummaryCourierPartnerCodDashboard(
        {
          type: "POS",
          date: moment(this.filterDate).format("YYYY-MM-DD"),
        }
      );
      this.summary = resp;
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(error, "Gagal Mendapatkan Summary!", () =>
          this.fetchSummary()
        )
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  // whatsapp courier / partner
  onCLickWhatsapp(item: CourierPartnerCodDashboard) {
    const templateMessage =
      `Halo Kurir ${item.courierName}\n` +
      `Kami menginformasikan ada STT COD perlu diselesaikan proses secepatnya:\n` +
      `${item.totalSttDel} STT COD masih proses pengiriman\n` +
      `${item.totalSttDexCollected +
        item.totalSttCodrejCollected} STT COD harus dikembalikan ke POS\n` +
      `${formatPrice(item.totalOutstanding)} Dana harus disetor ke POS\n` +
      `Harap langsung memproses pengiriman STT dan lakukan penyetoran Terima kasih`;
    whatsapp(item.courierPhone, templateMessage);
  }

  dailyDepositFromAmountDue = false;
  openPopupDailyDeposit = false;
  openPopupAmountDue = false;
  detailDataCourier = new CourierPartnerCodDashboard();
  openDailyDeposit(data: CourierPartnerCodDashboard) {
    this.detailDataCourier = data;
    this.openPopupDailyDeposit = true;
  }
  openDailyDepositFromAmountDue(data: CourierDetailPendingReconcile) {
    this.amountDueDate = data.dateGroup;
    this.dailyDepositFromAmountDue = true;
    this.openDailyDeposit(this.detailDataCourier);
  }
  closeDailyDeposit(success: boolean) {
    this.amountDueDate = "";

    if (this.dailyDepositFromAmountDue && !success) {
      this.openAmountDue();
    }

    if (success) {
      DashboardController.setListCourierPartnerCodDashboard(
        new ListCourierPartnerCodDashboard()
      );
      this.fetchCourierPartner();
    }
  }

  openAmountDue(data?: CourierPartnerCodDashboard) {
    this.detailDataCourier = data || this.detailDataCourier;
    this.openPopupAmountDue = true;
  }
  closeAmountDue() {
    this.dailyDepositFromAmountDue = false;
  }

  get cityCodes() {
    const cities = this.dataProfile.account_location_list?.map(
      (item) => item.city_code
    );
    return cities?.join(",");
  }

  downloadSummary() {
    const downloadType = this.accountTypeDetail === "pos" ? "pos" : "consol";
    const filter = new DownloadSummaryCodRequest({
      filterDate: moment(this.filterDate).format("YYYY-MM-DD"),
      cityCode: this.accountTypeDetail === "pos" ? "" : this.cityCodes,
    });

    DashboardController.getSummaryDownload({
      type: downloadType,
      filter: filter,
    });
  }
}
